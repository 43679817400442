export const useModifiers = (
  styles: { [x: string]: string } = {},
  base: string,
  mods: { [x: string]: boolean | null | undefined }
) => {
  return Object.keys(mods).reduce((accumulator, mod) => {
    if (!mods[mod]) return accumulator;
    return `${accumulator} ${styles[`${base}_${mod}`]}`;
  }, "");
};
