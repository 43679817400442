import React, { VoidFunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "components/layout/layout";
import { HomePage } from "pages/home";
import { OrderPage } from "./pages/order";

export const App: VoidFunctionComponent = () => {
  return (
    <Routes>
      <Route path={"/"} element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path={"order"} element={<OrderPage />} />
      </Route>
    </Routes>
  );
};
