import { DietTypes } from "../store/diets/types";
import { CSSProperties } from "react";

export const useDietColors = (type: DietTypes): CSSProperties => {
  switch (type) {
    case DietTypes.Vegetarian: {
      return {
        "--cThemeLocal": "var(--cDietV)",
        "--cThemeLocalActive": "var(--cDietVActive)",
      } as CSSProperties;
    }
    case DietTypes.Kcal750: {
      return {
        "--cThemeLocal": "var(--cDiet7)",
        "--cThemeLocalActive": "var(--cDiet7Active)",
      } as CSSProperties;
    }
    case DietTypes.Kcal1200: {
      return {
        "--cThemeLocal": "var(--cDiet12)",
        "--cThemeLocalActive": "var(--cDiet12Active)",
      } as CSSProperties;
    }
    case DietTypes.Kcal1500: {
      return {
        "--cThemeLocal": "var(--cDiet15)",
        "--cThemeLocalActive": "var(--cDiet15Active)",
      } as CSSProperties;
    }
    case DietTypes.Kcal1800: {
      return {
        "--cThemeLocal": "var(--cDiet18)",
        "--cThemeLocalActive": "var(--cDiet18Active)",
      } as CSSProperties;
    }
    case DietTypes.Kcal2500: {
      return {
        "--cThemeLocal": "var(--cDiet25)",
        "--cThemeLocalActive": "var(--cDiet25Active)",
      } as CSSProperties;
    }
    case DietTypes.Keto1500: {
      return {
        "--cThemeLocal": "var(--cDietK15)",
        "--cThemeLocalActive": "var(--cDietK15Active)",
      } as CSSProperties;
    }
    case DietTypes.Keto2500: {
      return {
        "--cThemeLocal": "var(--cDietK25)",
        "--cThemeLocalActive": "var(--cDietK25Active)",
      } as CSSProperties;
    }
    default: {
      return {};
    }
  }
};
