import { Title } from "../../components/layout/title";
import { Text } from "../../components/dictionary/text";
import { PageSection } from "../../components/layout/pageSection";
import { CardGrid } from "../../components/layout/cardGrid";
import { DietCard } from "../../components/cards/diet";
import { DietTypes } from "../../store/diets/types";
import { useGetDietsQuery } from "../../store/diets/endpoints";

const dummyDiet = {
  id: "",
  title: "Vegetara",
  image:
    "https://images.unsplash.com/photo-1504674900247-0877df9cc836?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80",
  type: DietTypes.Vegetarian,
  description: "Šī diēta satur ēdienus kopējo kalorijas daudzumu 750 kcal.",
  price: {
    value: 14.5,
    unit: "diena",
    locale: "lv-LV",
    currency: {
      symbol: "€",
      abbreviation: "EUR",
    },
  },
};

export const HomePage = () => {
  const { data } = useGetDietsQuery();

  console.log(data);
  return (
    <div>
      <PageSection
        title={
          <Title center>
            <Text k={"home.select-your-diet"} />
          </Title>
        }
      >
        <CardGrid>
          <DietCard
            diet={{
              ...dummyDiet,
              description:
                "Šī diēta satur ēdienus kopējo kalorijas daudzumu 750 kcal. Šī diēta satur ēdienus kopējo kalorijas daudzumu 750 kcal.",
            }}
          />
          <DietCard diet={{ ...dummyDiet, type: DietTypes.Kcal750 }} />
          <DietCard diet={{ ...dummyDiet, type: DietTypes.Kcal1200 }} />
          <DietCard diet={{ ...dummyDiet, type: DietTypes.Kcal1500 }} />
          <DietCard diet={{ ...dummyDiet, type: DietTypes.Kcal1800 }} />
          <DietCard diet={{ ...dummyDiet, type: DietTypes.Kcal2500 }} />
          <DietCard diet={{ ...dummyDiet, type: DietTypes.Keto1500 }} />
          <DietCard diet={{ ...dummyDiet, type: DietTypes.Keto2500 }} />
        </CardGrid>
      </PageSection>
    </div>
  );
};
