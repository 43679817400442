import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { index } from "./store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import 'focus-visible';
import "./style/theme.css";
import "./style/common.css";
import "./style/typography.css";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={index}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
