import { PriceType } from "../types/price";

export const useFormattedPrice = (price: PriceType): string => {
  let formattedPrice = new Intl.NumberFormat(price.locale, {
    style: "currency",
    currency: price.currency.abbreviation,
  }).format(price.value);
  if (price.unit) {
    formattedPrice += `/${price.unit}`;
  }
  return formattedPrice;
};
