import { FunctionComponent, HTMLAttributes, ReactNode } from "react";
import styles from './pageSection.module.css'

export interface PageSectionProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
  title?: ReactNode;
}

export const PageSection: FunctionComponent<PageSectionProps> = ({
  title,
  children,
  className,
  ...rest
}) => {
  return (
    <div className={`${styles.pageSection} ${className}`} {...rest}>
      {title ? <div className={styles.pageSection__title}>{title}</div> : null}
      <div>{children}</div>
    </div>
  );
};
