import { VoidFunctionComponent } from "react";
import { Outlet } from "react-router-dom";
import styles from "./layout.module.css";
import { Header } from "../header";

export const Layout: VoidFunctionComponent = () => {
  return (
    <div className={styles.layout}>
      <Header />
      <main className={styles.layout__content}>
        <div className={styles.layout__container}>
          <Outlet />
        </div>
      </main>
    </div>
  );
};
