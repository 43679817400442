import { VoidFunctionComponent } from "react";
import { ContentCard } from "../../components/cards/content";
import { Text } from "../../components/dictionary/text";
import { DeliveryCalendar } from "../../components/common/deliveryCalendar";

export const OrderPage: VoidFunctionComponent = () => {
  return <div>
    <ContentCard title={<Text k={`order.delivery.title`} />}>
      <DeliveryCalendar weeks={2}/>
    </ContentCard>
  </div>
};
