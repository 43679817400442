import { VoidFunctionComponent } from "react";
import { defaultDictionary } from "../dictionary";

export const useText = (k: string) => {
  return defaultDictionary[k] || k
}

export const Text: VoidFunctionComponent<{ k: string }> = ({ k }) => {
  const text = useText(k)
  return <>{text}</>;
};
