import styles from "./button.module.css";
import { FunctionComponent, HTMLAttributes, ReactNode } from "react";
import { useModifiers } from "hooks/useModifiers";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

type ButtonPropsType = {
  icon?: ReactNode;
  fullWidth?: boolean;
  wireframe?: boolean;
  vertical?: boolean;
  themeOutline?: boolean;
};

export interface ButtonProps
  extends HTMLAttributes<HTMLButtonElement>,
    ButtonPropsType {}
export interface LinkProps extends RouterLinkProps, ButtonPropsType {}

export const Button: FunctionComponent<ButtonProps> = ({
  icon,
  children,
  className,

  fullWidth,
  wireframe,
  vertical,
  themeOutline,
  ...rest
}) => {
  const mods = useModifiers(styles, "button", {
    fullWidth,
    wireframe,
    vertical,
    themeOutline,
  });

  return (
    <button className={`${styles.button} ${mods} ${className}`} {...rest}>
      <span className={styles.button__content}>
        {icon ? <span className={styles.button__icon}>{icon}</span> : null}
        <span>{children}</span>
      </span>
    </button>
  );
};

export const Link: FunctionComponent<LinkProps> = ({
  icon,
  children,
  className,

  fullWidth,
  wireframe,
  vertical,
  themeOutline,
  ...rest
}) => {
  const mods = useModifiers(styles, "button", {
    fullWidth,
    wireframe,
    vertical,
    themeOutline,
  });

  return (
    <RouterLink className={`${styles.button} ${mods} ${className}`} {...rest}>
      <span className={styles.button__content}>
        {icon ? <span className={styles.button__icon}>{icon}</span> : null}
        <span>{children}</span>
      </span>
    </RouterLink>
  );
};
