import { VoidFunctionComponent } from "react";
import { Logo } from "../logo";
import styles from "./header.module.css";

export const Header: VoidFunctionComponent = () => {
  return (
    <header className={styles.header}>
      <div className={styles.header__wing}>
        <Logo />
      </div>
      <div className={styles.header__wing}></div>
    </header>
  );
};
