export const Utensils = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.79954 13.1283L10.6295 10.2983L3.60954 3.28833C2.04954 4.84833 2.04954 7.37833 3.60954 8.94833L7.79954 13.1283V13.1283ZM14.5795 11.3183C16.1095 12.0283 18.2595 11.5283 19.8495 9.93833C21.7595 8.02833 22.1295 5.28833 20.6595 3.81833C19.1995 2.35833 16.4595 2.71833 14.5395 4.62833C12.9495 6.21833 12.4495 8.36833 13.1595 9.89833L3.39954 19.6583L4.80954 21.0683L11.6995 14.1983L18.5795 21.0783L19.9895 19.6683L13.1095 12.7883L14.5795 11.3183V11.3183Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Delivery = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 7C19 5.9 18.1 5 17 5H14V7H17V9.65L13.52 14H10V9H6C3.79 9 2 10.79 2 13V16H4C4 17.66 5.34 19 7 19C8.66 19 10 17.66 10 16H14.48L19 10.35V7ZM4 14V13C4 11.9 4.9 11 6 11H8V14H4ZM7 17C6.45 17 6 16.55 6 16H8C8 16.55 7.55 17 7 17Z"
        fill="currentColor"
      />
      <path d="M10 6H5V8H10V6Z" fill="currentColor" />
      <path
        d="M19 13C17.34 13 16 14.34 16 16C16 17.66 17.34 19 19 19C20.66 19 22 17.66 22 16C22 14.34 20.66 13 19 13ZM19 17C18.45 17 18 16.55 18 16C18 15.45 18.45 15 19 15C19.55 15 20 15.45 20 16C20 16.55 19.55 17 19 17Z"
        fill="currentColor"
      />
    </svg>
  );
};
