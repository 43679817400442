import { FunctionComponent, HTMLAttributes, ReactNode } from "react";
import styles from './contentCard.module.css'

export interface ContentCardProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  title?: string | ReactNode
}

export const ContentCard: FunctionComponent<ContentCardProps> = ({title, children, className, ...rest}) => {
  return <div className={`${styles.contentCard} ${className}`} {...rest}>
    {title ? <div className={`${styles.contentCard__title}`}><h2 className={`h3 h3_noMargin`}>{title}</h2></div> : null}
    {children}
  </div>
}