import { HTMLAttributes, VoidFunctionComponent } from "react";
import styles from "./dietCard.module.css";
import { Button, Link } from "../../common/button";
import { DietType } from "../../../store/diets/types";
import { Text } from "../../dictionary/text";
import { useFormattedPrice } from "../../../hooks/useFormattedPrice";
import { Utensils } from "../../icons";
import { useDietColors } from "../../../hooks/useDietColors";

export interface DietCardProps extends HTMLAttributes<HTMLDivElement> {
  diet: DietType;
}

export const DietCard: VoidFunctionComponent<DietCardProps> = ({
  diet,
  className,
  ...rest
}) => {
  const dietColors = useDietColors(diet.type);
  const price = useFormattedPrice(diet.price);

  return (
    <article
      className={`${styles.dietCard} ${className}`}
      style={dietColors}
      {...rest}
    >
      <img className={styles.dietCard__image} src={diet.image} alt={""} />
      <div className={styles.dietCard__body}>
        <div className={styles.dietCard__container}>
          <div className={styles.dietCard__content}>
            <h2 className={`h3 h3_noMargin ${styles.dietCard__title}`}>
              {diet.title}
            </h2>
            <div className={`text ${styles.dietCard__description}`}>
              <p>{diet.description}</p>
            </div>
          </div>
          <div className={styles.dietCard__menu}>
            <Button vertical wireframe themeOutline icon={<Utensils />}>
              <Text k={"common.menu"} />
            </Button>
          </div>
        </div>
        <div className={styles.dietCard__price}>
          <div className={styles.dietCard__priceLabel}>
            <Text k={"common.price-starting-from"} />
          </div>
          <div className={styles.dietCard__priceValue}>{price}</div>
        </div>
        <div className={styles.dietCard__action}>
          <Link to={"/order"} fullWidth>
            Pasūtīt
          </Link>
        </div>
      </div>
    </article>
  );
};
