import {
  addDays,
  format,
  getDay,
  isAfter,
  isBefore,
  isSameDay,
  startOfWeek,
} from "date-fns";
import { lv } from "date-fns/locale";
import styles from "./deliveryCalendar.module.css";
import { CSSProperties, FunctionComponent, VoidFunctionComponent } from "react";
import { useModifiers } from "../../../hooks/useModifiers";
import { Delivery } from "../../icons";
const deliveryDays = [0, 1, 3, 5];

const Day: FunctionComponent<{
  today?: boolean;
  included?: boolean;
  header?: boolean;
  row: number;
  column: number;
}> = ({ today, included, header, row, column, children }) => {
  const mods = useModifiers(styles, "deliveryCalendar__day", {
    today,
    included,
    header,
  });
  return (
    <div
      className={`${styles.deliveryCalendar__day} ${mods}`}
      style={{
        gridColumn: column,
        gridRow: row,
      }}
    >
      {children}
    </div>
  );
};

export interface DeliveryCalendarProps {
  weeks: number;
}

export const DeliveryCalendar: VoidFunctionComponent<DeliveryCalendarProps> = ({
  weeks,
}) => {
  const today = new Date();
  const staringDate = startOfWeek(today, { locale: lv });

  const getDays = () => {
    const days = [];
    const nextDelivery = (() => {
      const todayDayOfTheWeek = getDay(today);
      const offsetDay = (todayDayOfTheWeek + 2) % 7;
      const nextDelivery =
        offsetDay > Math.max(...deliveryDays)
          ? Math.min(...deliveryDays)
          : (deliveryDays
              .sort((a, b) => a - b)
              .find((item) => offsetDay <= item) as number);
      console.log(nextDelivery);
      return addDays(
        today,
        nextDelivery > todayDayOfTheWeek
          ? nextDelivery - todayDayOfTheWeek
          : nextDelivery + 7 - todayDayOfTheWeek
      );
    })();

    // console.log(nextDelivery);

    const isIncluded = (currentDate: Date): boolean => {
      return (
        (isAfter(currentDate, nextDelivery) ||
          isSameDay(currentDate, nextDelivery)) &&
        isBefore(currentDate, addDays(nextDelivery, weeks * 7 - 1))
      );
    };

    for (let i = 0; i < 7 * 5; i++) {
      const currentDate = addDays(staringDate, i);
      days.push(
        <Day
          today={isSameDay(currentDate, today)}
          included={isIncluded(currentDate)}
          column={(i % 7) + 1}
          row={Math.floor(i / 7) + 2}
          key={i}
        >
          {format(currentDate, "d")}
        </Day>
      );
    }
    return days;
  };

  return (
    <div
      className={styles.deliveryCalendar}
      style={{ "--numberOfRows": 6 } as CSSProperties}
    >
      {deliveryDays.map((day) => (
        <>
          <div
            className={`${styles.deliveryCalendar__deliveryOutline} ${styles.deliveryCalendar__deliveryOutline_hoverZone}`}
            style={
              {
                "--currentColumn": day - 1 >= 0 ? day : day + 7,
              } as CSSProperties
            }
          />
          <div
            className={`${styles.deliveryCalendar__deliveryOutline}`}
            style={
              {
                "--currentColumn": day - 1 >= 0 ? day : day + 7,
              } as CSSProperties
            }
          />
        </>
      ))}
      {new Array(7).fill("").map((item, index) => (
        <Day row={1} column={index + 1} header>
          <div className={styles.deliveryCalendar__header}>
            {deliveryDays.includes(getDay(addDays(staringDate, index))) ? (
              <Delivery />
            ) : null}{" "}
            <div>
              {format(addDays(staringDate, index), "EEEEEE", { locale: lv })}
            </div>
          </div>
        </Day>
      ))}
      {getDays()}
    </div>
  );
};
